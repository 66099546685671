<template>
    <div class="popup">
        <v-btn
            color="indigo"
            dark
            medium
            @click="popup = !popup"
        >
            Dodaj koszt
        </v-btn>

        <v-dialog
            v-model="popup"
            max-width="500px"
        >
            <v-card>
				<v-form ref="form" >
					<v-card-title>
						Dodaj koszt
					</v-card-title>

					<v-card-text>

						<v-text-field
							v-model="name"
							label="Nazwa"
							clearable
							:error-messages="nameErrors"
							@input="$v.name.$touch()"
							@blur="$v.name.$touch()"
						></v-text-field>

						<v-text-field
							v-model="cost"
							label="Koszt"
							clearable
							:error-messages="costErrors"
							@input="$v.cost.$touch()"
							@blur="$v.cost.$touch()"
						></v-text-field>

					</v-card-text>

					<v-card-actions class="ml-4">

						<v-btn
							color="primary"
							@click="addCost"
							:disabled="$v.$invalid"
							:loading="isLoaded"
						>
							Dodaj
						</v-btn>

					</v-card-actions>
				</v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
const { required,  decimal } = require('vuelidate/lib/validators')
import { errorMsg } from '@/mixins/Validation';

export default{
    name: 'Popup',
    mixins: [validationMixin, errorMsg],

    data(){
        return{
            popup: false,
            name: null,
			cost: null,
			isLoaded: false,
        }
    },
    validations: {
        name: {
            required,
        },
        cost: {
            decimal,
            required
        },
    },
    methods:{
		resetValidation(){
			this.$refs.form.reset()
			this.$nextTick(() => { this.$v.$reset() })
		},
        addCost(){
            let calcDate = this.$parent.date.split("-");

            let year = parseFloat(calcDate[0]);
            let month = parseFloat(calcDate[1]);
            if(!this.$v.$invalid){
				this.isLoaded = true
                this.$axios.post(`${this.url}api/costs`, {
                    name: this.name,
                    cost: parseFloat(this.cost),
                    date: new Date(year, month),
                    paid: false
                }).then(()=> {
					this.resetValidation();
					this.popup = false;
					this.isLoaded = false;
				})
            }
        }
    }
}
</script>
