<template>
    <div>
        <v-container class="my-5">
            <v-row>
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="350px"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-model="date"
                            readonly
                            v-on="on"
                            color="indigo"
                            dark
                            class="mr-5 ml-3 mb-4"
                        >
                            {{ date }}
                        </v-btn>
                    </template>

                    <v-date-picker
                        v-model="date"
                        scrollable
                        type="month"
                        @change="dateChange(date)"
                    >
                        <v-spacer></v-spacer>

                        <v-btn text color="primary" @click="modal = false"
                            >Anuluj</v-btn
                        >
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                            >OK</v-btn
                        >
                    </v-date-picker>
                </v-dialog>

                <AddCost v-if="role === 'administrator'" />
            </v-row>

            <v-row v-if="role === 'administrator'">
                <v-col>
                    <v-card class="mx-auto">
                        <v-card-title>{{ calcFullRate }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Przychód</v-card-subtitle
                        >
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <v-card-title>{{ calcCopyRate }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Koszty copywriterów</v-card-subtitle
                        >
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <v-card-title>0 zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Koszty korektorów</v-card-subtitle
                        >
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <v-card-title>{{ calculateIncome }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Dochód</v-card-subtitle
                        >
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <v-card-title>{{ calcCosts }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Koszty stałe</v-card-subtitle
                        >
                    </v-card>
                </v-col>

                <v-col>
                    <v-card>
                        <v-card-title>{{ calcBrutto }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Zysk brutto</v-card-subtitle
                        >
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="tasks"
                        item-key="id"
                        class="elevation-2"
                        :hide-default-footer="true"
                        :disable-pagination="true"
                        :sort-desc="false"
                        group-by="clientId.name"
                    >
                        <template v-slot:item.clientId="{ item }">
                            {{ item.clientId ? item.clientId.name : "" }}
                        </template>

                        <template v-slot:item.email="{ item }">
                            <v-btn
                                type="button"
                                small
                                color="indigo"
                                dark
                                text
                                v-clipboard:copy="
                                    item.clientId ? item.clientId.email : ''
                                "
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.nip="{ item }">
                            <v-btn
                                type="button"
                                small
                                color="indigo"
                                dark
                                text
                                v-clipboard:copy="
                                    item.clientId ? item.clientId.nip : ''
                                "
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.date="{ item }">
                            {{ item.date ? item.date.substr(0, 10) : "" }}
                        </template>

                        <template v-slot:item.fullRate="{ item }">
                            {{ item.fullRate ? item.fullRate.toFixed(2) : "" }}
                        </template>

                        <template v-slot:item.paid="{ item }">
                            <v-checkbox
                                v-model="item.paid"
                                @change="updateTaskPaid(item._id, item.paid)"
                            ></v-checkbox>
                        </template>
                    </v-data-table>
                </v-col>

                <v-col class="col-4" v-if="role === 'administrator'">
                    <v-row>
                        <v-col>
                            <v-data-table
                                :headers="costsHeaders"
                                :items="costs"
                                item-key="id"
                                class="elevation-2"
                                :hide-default-footer="true"
                                :disable-pagination="true"
                                :sort-desc="false"
                            >
                                <template v-slot:item.paid="{ item }">
                                    <v-checkbox
                                        v-model="item.paid"
                                        @change="
                                            updateCost(item._id, item.paid)
                                        "
                                    ></v-checkbox>
                                </template>

                                <template v-slot:item.actions="{ item }">
                                    <v-btn
                                        color="red"
                                        class="mt-1 mb-1 mr-1 white--text"
                                        fab
                                        small
                                        @click.stop="deleteCost(item._id)"
                                    >
                                        <v-icon dark
                                            >mdi-trash-can-outline</v-icon
                                        >
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>

                    <v-row class="mt-3">
                        <v-col>
                            <v-btn
                                @click="showCopyCosts = !showCopyCosts"
                                class="mb-3"
                            >
                                {{ showCopyCosts ? "Schowaj" : "Pokaż" }} koszty
                                Copywriterów
                            </v-btn>
                            <v-data-table
                                v-if="showCopyCosts"
                                :headers="copyCostsHeaders"
                                :items="
                                    getCopyAllCosts.sort((a, b) => {
                                        if (
                                            a.name.toLowerCase() <
                                            b.name.toLowerCase()
                                        ) {
                                            return -1;
                                        }
                                        if (
                                            a.name.toLowerCase() >
                                            b.name.toLowerCase()
                                        ) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                "
                                item-key="id"
                                class="elevation-2"
                                :hide-default-footer="true"
                                :disable-pagination="true"
                                :sort-desc="false"
                            >
                                <template v-slot:item.rate="{ item }">
                                    {{ item.rate.toFixed(2) }}
                                </template>

                                <template v-slot:item.paid="{ item }">
                                    <v-checkbox
                                        v-model="item.paid"
                                        @change="
                                            updatePaid(item.uid, item.paid)
                                        "
                                    ></v-checkbox>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col>
                            <v-btn
                                @click="showKorektorCosts = !showKorektorCosts"
                                class="mb-3"
                            >
                                {{ showKorektorCosts ? "Schowaj" : "Pokaż" }}
                                koszty Korektorów
                            </v-btn>
                            <v-data-table
                                v-if="showKorektorCosts"
                                :headers="korektorCostsHeaders"
                                :items="
                                    getKorektorAllCosts.sort((a, b) => {
                                        if (
                                            a.name.toLowerCase() <
                                            b.name.toLowerCase()
                                        ) {
                                            return -1;
                                        }
                                        if (
                                            a.name.toLowerCase() >
                                            b.name.toLowerCase()
                                        ) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                "
                                item-key="id"
                                class="elevation-2"
                                :hide-default-footer="true"
                                :disable-pagination="true"
                                :sort-desc="false"
                            >
                                <template v-slot:item.rate="{ item }">
                                    {{
                                        item.email == tutor
                                            ? (tutorCost + item.rate).toFixed(2)
                                            : item.rate.toFixed(2)
                                    }}
                                </template>

                                <template v-slot:item.paid="{ item }">
                                    <v-checkbox
                                        v-model="item.paid"
                                        @change="
                                            updatePaidKorektor(
                                                item.uid,
                                                item.paid
                                            )
                                        "
                                    ></v-checkbox>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <div class="hide">
            {{ getCopyAllCosts }}
            {{ getKorektorAllCosts }}
        </div>
    </div>
</template>

<script>
import AddCost from "@/components/molecules/AddCost.vue";
import io from "socket.io-client";

export default {
    data() {
        return {
            showCopyCosts: false,
            showKorektorCosts: false,
            showTutorCosts: false,
            tasks: [],
            costs: [],
            tutorCost: 0,
            tutor: "",
            clients: [],
            socket: io(this.url),
            headers: [
                {
                    text: "Zadanie",
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                { text: "Klient", value: "clientId", sortable: true },
                { text: "Deadline", value: "date", sortable: true },
                { text: "Stawka", value: "fullRate", sortable: true },
                { text: "Znaki", value: "characters", sortable: true },
                { text: "NIP/Adres", value: "nip", sortable: true },
                { text: "Email", value: "email", sortable: true },

                { text: "Zapłacone", value: "paid", sortable: true },
            ],
            costsHeaders: [
                {
                    text: "Pozycja",
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                { text: "Koszt", align: "left", sortable: true, value: "cost" },
                {
                    text: "Zapłacone",
                    align: "left",
                    sortable: true,
                    value: "paid",
                },
                {
                    text: "Akcje",
                    align: "left",
                    sortable: true,
                    value: "actions",
                },
            ],
            copyCostsHeaders: [
                {
                    text: "Imię i nazwisko",
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                { text: "Koszt", align: "left", sortable: true, value: "rate" },
                {
                    text: "Zapłacone",
                    align: "left",
                    sortable: true,
                    value: "paid",
                },
            ],
            korektorCostsHeaders: [
                {
                    text: "Imię i nazwisko",
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                { text: "Koszt", align: "left", sortable: true, value: "rate" },
                {
                    text: "Zapłacone",
                    align: "left",
                    sortable: true,
                    value: "paid",
                },
            ],
            date: this.$route.params.month,
            modal: false,
            isCostView: false,
        };
    },

    methods: {
        getPosts() {
            this.$axios
                .get(`${this.url}api/tasks/payments/${this.date}`)
                .then((resp) => {
                    this.tasks = resp.data;
                });
        },
        getAllAppretices() {
            this.$axios
                .get(`${this.url}api/apprentice/payment/${this.date}`)
                .then(
                    (el) =>
                        (this.tutorCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 2)
                );
        },
        getTutor() {
            this.$axios
                .get(`${this.url}api/users/practice-tutor`)
                .then((response) => {
                    this.$axios
                        .get(
                            `${this.url}api/apprentice/current-tutor/${response.data.email}`
                        )
                        .then((el) => {
                            if (el.data) {
                                if (el.data.tutor) {
                                    this.tutor = el.data.tutor.email;
                                }
                            }
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        getCosts() {
            this.$axios
                .get(`${this.url}api/costs/${this.date}`)
                .then((resp) => {
                    this.costs = resp.data;
                });
        },
        updateCost(itemId, itemPaid) {
            this.$axios
                .put(`${this.url}api/costs`, {
                    id: itemId,
                    paid: itemPaid,
                })
                .catch((err) => {
                    throw Error(err);
                });
        },
        updateTaskPaid(itemId, itemPaid) {
            this.$axios
                .patch(`${this.url}api/tasks/paid`, {
                    id: itemId,
                    paid: itemPaid,
                })
                .catch((err) => {
                    throw Error(err);
                });
        },
        deleteCost(id) {
            this.$axios.delete(`${this.url}api/costs/${id}`).catch((error) => {
                throw error.response.data;
            });
        },
        // New
        dateChange(date) {
            this.$router.push(`../../${this.role}/payments/${date}`);
            this.getPosts();
            this.getCosts();
            this.getAllAppretices();
            this.getTutor();
        },
        updatePaid(uid, paid) {
            this.$axios
                .put(`${this.url}api/paids`, {
                    uid: uid,
                    paid: paid,
                    date: this.date,
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        updatePaidKorektor(uid, paid) {
            this.$axios
                .put(`${this.url}api/paids`, {
                    uid: uid,
                    paid: paid,
                    date: this.date,
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    created() {
        this.getPosts();
        this.getCosts();
        this.getAllAppretices();
        this.getTutor();

        this.socket.on("newTaskAdded", () => {
            this.getPosts();
        });

        this.socket.on("taskPaidUpdate", () => {
            this.getPosts();
        });

        this.socket.on("newCostAdded", () => {
            this.getCosts();
        });

        this.socket.on("costUpdated", () => {
            this.getCosts();
        });

        this.socket.on("costDeleted", () => {
            this.getCosts();
        });

        this.socket.on("taskDeleted", () => {
            this.getPosts();
        });
        this.socket.on("newTaskAprrenticeEdited", () => {
            this.getPosts();
            this.getAllAppretices();
        });
        this.socket.on("appreticeChangedStatus", () => {
            this.getPosts();
            this.getAllAppretices();
        });
    },
    computed: {
        role() {
            return this.$store.getters.getUserRole;
        },
        calcAllKorektor() {
            // Get all task
            const allTasks = this.tasks;
            const tbl = [];
            // All tasks loop that throws korektor id
            allTasks.forEach((item) => {
                if (item.korektorId != null) {
                    if (!tbl.includes(item.korektorId._id)) {
                        tbl.push(item.korektorId._id);
                    }
                }
            });

            const sumItems = (id) => {
                let results = {
                    rate: 0,
                    name: "",
                    paid: false,
                    uid: "",
                    email: "",
                };

                const filtredTasks = allTasks.filter((i) => {
                    if (i.korektorId != null) {
                        return i.korektorId._id === id;
                    }
                });

                filtredTasks.forEach((el) => {
                    // new rate for corrector
                    if (el.corectorRate) {
                        results.rate += el.corectorRate;
                    } else {
                        results.rate += el.characters / 1000;
                    }
                    // end new rate
                    results.name = el.korektorId.name;
                    results.uid = el.korektorId._id;
                    results.email = el.korektorId.email;
                    results.paid = false;
                });

                return results;
            };

            let result = [];

            tbl.forEach((item) => {
                result.push(sumItems(item));
            });
            return result;
        },
        calcAllCopy() {
            // Get all task
            const allTasks = this.tasks;
            const tbl = [];

            // All tasks loop that throws copywriters id
            allTasks.forEach((item) => {
                if (item.copywriterId != null) {
                    if (!tbl.includes(item.copywriterId._id)) {
                        tbl.push(item.copywriterId._id);
                    }
                }
            });

            const sumItems = (id) => {
                return allTasks
                    .filter((i) => {
                        if (i.copywriterId != null) {
                            return i.copywriterId._id === id;
                        }
                    })
                    .reduce(
                        (a, b) => {
                            return {
                                rate: a.rate + b.copyRate,
                                name: b.copywriterId.name,
                                uid: id,
                                paid: false,
                            };
                        },
                        { rate: 0, name: "", paid: false }
                    );
            };

            let result = [];

            tbl.forEach((item) => {
                result.push(sumItems(item));
            });
            return result;
        },
        getTutorAllCosts() {
            return [
                {
                    rate: this.tutorCost,
                    name: this.tutor.name,
                    uid: this.tutor.id,
                },
            ];
        },
        // calcKorektorAllCosts() {
        //     let arr = this.getKorektorAllCosts;
        //     let result = arr.reduce(function (previousValue, currentValue) {
        //         return previousValue + currentValue.rate;
        //     }, 0);

        //     return result.toFixed(2);
        // },
        getKorektorAllCosts() {
            let fullArray = this.calcAllKorektor;
            let arr = [];
            fullArray.forEach((item) => {
                this.$axios
                    .get(`${this.url}api/paids/${item.uid}/${this.date}`)
                    .then((resp) => {
                        if (resp.data == null) {
                            this.$axios.post(`${this.url}api/paids/`, {
                                uid: item.uid,
                                paid: false,
                                date: this.date,
                                email: item.email,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
            fullArray.forEach((item) => {
                this.$axios
                    .get(`${this.url}api/paids/${item.uid}/${this.date}`)
                    .then((resp) => {
                        if (resp.data != null) {
                            arr.push({
                                uid: item.uid,
                                name: item.name,
                                paid: resp.data.paid ? resp.data.paid : false,
                                rate: item.rate,
                                email: item.email,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });

            return arr;
        },
        getCopyAllCosts() {
            let fullArray = this.calcAllCopy;
            let arr = [];
            fullArray.forEach((item) => {
                this.$axios
                    .get(`${this.url}api/paids/${item.uid}/${this.date}`)
                    .then((resp) => {
                        if (resp.data == null) {
                            this.$axios.post(`${this.url}api/paids/`, {
                                uid: item.uid,
                                paid: false,
                                date: this.date,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
            fullArray.forEach((item) => {
                this.$axios
                    .get(`${this.url}api/paids/${item.uid}/${this.date}`)
                    .then((resp) => {
                        if (resp.data != null) {
                            arr.push({
                                uid: item.uid,
                                name: item.name,
                                paid: resp.data.paid ? resp.data.paid : false,
                                rate: item.rate,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });

            return arr;
        },
        calcCopyRate() {
            let allTasks = this.tasks;
            let result = [];

            result = allTasks.reduce(function (previousValue, currentValue) {
                return previousValue + currentValue.copyRate;
            }, 0);

            return result.toFixed(2);
        },
        calcFullRate() {
            let allTasks = this.tasks;
            let result = [];

            result = allTasks.reduce(function (previousValue, currentValue) {
                return previousValue + currentValue.fullRate;
            }, 0);

            return result.toFixed(2);
        },
        calcCosts() {
            let allCosts = this.costs;
            let result = [];

            result = allCosts.reduce(function (previousValue, currentValue) {
                return previousValue + currentValue.cost;
            }, 0);

            return result.toFixed(2);
        },
        calculateIncome() {
            return (this.calcFullRate - this.calcCopyRate).toFixed(2);
        },
        calcMarginNetto() {
            let value = 0;
            value = this.calcFullRate / this.calculateIncome;

            return value.toFixed(2);
        },
        calcMarginBrutto() {
            let value = 0;
            value = this.calcFullRate / this.calculateIncome;

            return value.toFixed(2);
        },
        calcBrutto() {
            return (
                this.calcFullRate -
                this.calcCopyRate -
                this.calcCosts
            ).toFixed(2);
        },
    },
    components: {
        AddCost,
    },
};
</script>

<style lang="scss" scoped>
.hide {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 0;
    height: 0;
}
</style>
